import React from 'react';
import styled from 'styled-components';
import StatsBox from './StatsBox';
import { Flex } from './shared/Flex';
import { isMobile } from '@binah/web-sdk';
import Icon from '../assets/Icon.svg'
import media from '../style/media';
const Wrapper = styled(Flex)`
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 3px;
  padding: 13px 50px;
  bottom: 30px;
  box-sizing: border-box;
`;
const ButtonWrapper = styled(Flex)`
  flex: 2;
  z-index: 3;
  width: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-top: -30px;
  ${media.mobile`
    margin-top: 50px;
  `}
  ${media.tablet`
  padding: 0;
  height: auto;
  width: auto;
  position: absolute;
  right: 0;
  bottom: 42%;
  margin-right: 60px;
`}
`;

const BoxesWrapper = styled(Flex)`
  gap: 30px;
`;
const Head = styled.div`
position:relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top:0.8em;
  text-align: center;
`;

const Cards = styled.section`
position:fixed;

rigth:2%;
top:30%;
  background-color: #EDEDED;
  width: 300px;
  height: auto;
  
  border-radius: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  padding:0;
padding-bottom:1.2em;


  @media (max-width: 1000px) {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    rigth:0%;
    border-radius:0;
    height: 150px;
    top:90%;
  }

    @media (max-width: 1000px) {
    display: flex;
    justify-content: start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    rigth:0%;
    border-radius:0;
    height: 150px;
    top:90%;
  }
`;

const Logo = styled.div`
position:absolute;
left:0;
  width: 10%;
  margin-left: 1.6em;
 
    @media (max-width: 1000px) {
    font-size: 0.8em;
    width: 6%;
  }
      @media (max-width: 475px) {
    font-size: 0.8em;
    width: 9%;
  }
  
`;

const ImageLogo = styled.img`
  width: 100%;
`;

const Info = styled.div`
display:flex;
justify-content: center;
flex-wrap:wrap;
  width: 100%;
  margin: 0px;
  margin:0px auto;
 
`;

const InfoText = styled.p`
  line-height: 1.5; /* Adjust line-height for better readability */
`;

const SubTitle = styled.h2`
  width: 80%;
  text-align: center;
  font-size: 1.2em;
    @media (max-width: 1000px) {
    font-size: 1.2em;
  }
    @media (max-width: 320px) {
   font-size: 1em;
  }
  @media (max-width: 425px) {
    font-size: 0.7em;
   }
`;



interface VitalSign<T> {
  value?: T;
  isEnabled: boolean;
}

interface IStats {
  /**
   *  Object - contains health stats info
   */
  vitalSigns: {
    heartRate: VitalSign<number>;
    oxygenSaturation: VitalSign<number>
    breathingRate: VitalSign<number>;
    spo2: VitalSign<number>;
    sdnn: VitalSign<number>;
    stress: VitalSign<number>;
    bloodPressure: VitalSign<BloodPressureValue>;
    Rri: VitalSign<number>;
    MeanRri: VitalSign<number>;
    Rmssd: VitalSign<number>;
    Sd1: VitalSign<number>;
    Sd2: VitalSign<number>;
    Prq: VitalSign<number>;
    wellnessLevel: VitalSign<number>;
    Lfhf: VitalSign<number>;
    hemoglobin: VitalSign<number>;
    hemoglobinA1c: VitalSign<number>;
    hrvSdnn: VitalSign<number>;
  };
}

export enum PnsZone {
  DESCONOCIDO,
  BAJO,
  NORMAL,
  ALTO
};

export type BloodPressureValue = {
  systolic: number;
  diastolic: number;
};

const Stats = ({ vitalSigns }: IStats) => {
  const bloodPressureToDisplay =
    vitalSigns.bloodPressure.value?.systolic &&
      vitalSigns.bloodPressure.value?.diastolic
      ? vitalSigns.bloodPressure.value.systolic +
      '/' +
      vitalSigns.bloodPressure.value.diastolic
      : '--';

  const StressLevelIndex = ['DESCONOCIDO', 'BAJO', 'NORMAL', 'LEVEL', 'ALTO', 'EXTREMO'];
  const WellnessLevel = ['DESCONOCIDO', 'BAJO', 'NORMAL', 'ALTO'];

  const wellNessLevel = WellnessLevel[vitalSigns.wellnessLevel.value];
  const stressLevel = StressLevelIndex[vitalSigns.stress.value];

  console.log('vitalSigns: ', vitalSigns);
  return (
    <Cards>
      <Head>
        <Logo>
          <ImageLogo src={Icon} alt="Logo" />
        </Logo>
        <SubTitle>RESULTADOS</SubTitle>
      </Head>
      <Info>
        <StatsBox
          title={'Frecuencia Cardiaca'}
          value={
            vitalSigns.heartRate.isEnabled
              ? vitalSigns.heartRate.value || '--'
              : 'N/A'
          }
        />

        <StatsBox
          title={'Frecuencia Respiratoria'}
          value={
            vitalSigns.breathingRate.isEnabled
              ? vitalSigns.breathingRate.value || '--'
              : 'N/A'
          }
        />

        {/*         <StatsBox
          title={'Saturación de Oxigeno'}
          value={
            vitalSigns.oxygenSaturation.isEnabled
              ? vitalSigns.oxygenSaturation.value || '--'
              : 'N/A'
          }
        /> */}

        <StatsBox
          title={'Presión Arterial'}
          value={
            vitalSigns.bloodPressure.isEnabled
              ? bloodPressureToDisplay
              : 'N/A'
          }
        />

        <StatsBox
          title={'Sdnn'}
          value={
            vitalSigns.sdnn.isEnabled
              ? vitalSigns.sdnn.value || '--'
              : 'N/A'
          }
        />
        <StatsBox
          title={'Estres'}
          value={
            vitalSigns.stress.isEnabled
              ? stressLevel || '--'
              : 'N/A'
          }
        />
        <StatsBox
          title={'HRV-SDNN'}
          value={
            vitalSigns.hrvSdnn.isEnabled
              ? vitalSigns.hrvSdnn.value || '--'
              : 'N/A'
          }
        />

        <StatsBox
          title={'MeanRri'}
          value={
            vitalSigns.MeanRri.isEnabled
              ? vitalSigns.MeanRri.value || '--'
              : 'N/A'
          }
        />

        <StatsBox
          title={'Rmssd'}
          value={
            vitalSigns.Rmssd.isEnabled
              ? vitalSigns.Rmssd.value || '--'
              : 'N/A'
          }
        />
        <StatsBox
          title={'PRQ'}
          value={
            vitalSigns.Prq.isEnabled
              ? vitalSigns.Prq.value || '--'
              : 'N/A'
          }
        />

        <StatsBox
          title={'Bienestar'}
          value={
            vitalSigns.wellnessLevel.isEnabled
              ? wellNessLevel || '--'
              : 'N/A'
          }
        />
        <StatsBox
          title={'Lfhf'}
          value={
            vitalSigns.Lfhf.isEnabled ? vitalSigns.Lfhf.value || '--' : 'N/A'
          }
        />


        <StatsBox
          title={'Hemoglobina'}
          value={
            vitalSigns.hemoglobin.isEnabled
              ? vitalSigns.hemoglobin.value || '--'
              : 'N/A'
          }
        />

        <StatsBox
          title={'Hemoglobina A1C'}
          value={
            vitalSigns.hemoglobinA1c.isEnabled
              ? vitalSigns.hemoglobinA1c.value || '--'
              : 'N/A'
          }
        />

      </Info>
    </Cards>
  );
};

export default Stats;
